.incoming
{
    margin-bottom: 2em !important;
}
.future_eps .card-block:not(:first-child)
{
    padding-top: 0.5em !important;
    padding-bottom: 0.5em !important;
}
.future_eps small
{
    position: absolute;
    margin-left: 0.3em;
    color: black;
}
}
.md-input-container
{
    width:100%;
}
.ellipsis
{
    text-overflow: ellipsis;
}
.card-title.ellipsis
{
    display: list-item;
    width:8em;
}
.card-header h1
{
    display: inline;
    font-size: 2rem;
}
.vertical-text {
    display: block;
    word-wrap: break-word;
    width: 1px !important;
}
.up {
    font-size: 15px;
    font-weight: bold;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    padding: 3em 0 0 0.6em;
    box-sizing: border-box;
    text-align: center;
    color: #263849;
    opacity: 0.7;
}

.up:after {
    content: "";
    position: absolute;
    top: 1.5em;
    left: 0.3em;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 2px solid #263849
}

.up:before {
    content: "";
    position: absolute;
    top: 2em;
    left: 0.7em;
    width: 8px;
    height: 8px;
    border-right: 2px solid #263849;
    border-bottom: 2px solid #263849;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg)
}

.up:hover {
    color: #263849;
    background: #b8dadf;
    box-shadow: 0 0.13em 3px 0.04em rgba(0,0,0,0.12);
    
    border-radius: 5px;
}

@media (max-width: 1230px) {
    .up {
        text-indent:-9999px;
        width: 50px
    }
}

@media (max-width: 1090px) {
    .up {
        display:none
    }
}
.card-title
{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.octo, .octo div {
  margin: 0 auto;
  transform-origin: 50% 50%;
  overflow: hidden;
  
}
.octo, .octo div {
  
}
.octo {
  
}
.octo1 {
  transform: rotate(-45deg);
}

.octo1 img{
  background: darkseagreen;
}
.collapse.show
{
        display: table;
}
.people
{
    display: inline-block;
}

.jumbotron.mein
{
    padding-top: 0 !important;
    margin-bottom: 0 !important;
}
nav .h1
{
    margin-bottom: 0 !important;
}
.viveo_search_result
{
    
}
.collapse  .card-block
{
    display: inline-block;
}
.collapse  .card-block .ep_thumb
{
    display: inline-block;
}
.no-margin
{
    margin:0px !important ;
}
.card
{
    margin-bottom: 1em;
}
.card-title
{
    display: inline;
}
.torrent_download
{
    top: 4em;
    right: 1em;
    position: absolute;
}
.ep_thump {
    height: 22em;
}
.video-list-thumbs{}
.video-list-thumbs > li{
    margin-bottom:12px;
}
.video-list-thumbs > li:last-child{}
.video-list-thumbs > li > a{
	display:block;
	position:relative;
	background-color: #111;
	color: #fff;
	padding: 8px;
	border-radius:3px
    transition:all 500ms ease-in-out;
    border-radius:4px
}
.video-list-thumbs > li > a:hover{
	box-shadow:0 2px 5px rgba(0,0,0,.3);
	text-decoration:none
}
.video-list-thumbs h2{
	bottom: 0;
	font-size: 14px;
	height: 33px;
	margin: 8px 0 0;
}
.video-list-thumbs .glyphicon-play-circle{
    font-size: 60px;
    opacity: 0.6;
    position: absolute;
    right: 39%;
    top: 31%;
    text-shadow: 0 1px 3px rgba(0,0,0,.5);
    transition:all 500ms ease-in-out;
}
.video-list-thumbs > li > a:hover .glyphicon-play-circle{
	color:#fff;
	opacity:1;
	text-shadow:0 1px 3px rgba(0,0,0,.8);
}
.video-list-thumbs .duration{
	background-color: rgba(0, 0, 0, 0.4);
	border-radius: 2px;
	color: #fff;
	font-size: 11px;
	font-weight: bold;
	left: 12px;
	line-height: 13px;
	padding: 2px 3px 1px;
	position: absolute;
	top: 12px;
    transition:all 500ms ease;
}
.video-list-thumbs > li > a:hover .duration{
	background-color:#000;
}
.video-list-thumbs img
{
    width:100%;
}
.video-list-thumbs .card-img-overlay.title
{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px !important;
    top: 0em !important;
}

.video-list-thumbs .card-img-overlay.title .card-text
{
    width:100%;
}

.video-list-thumbs .card-img-overlay 
{
    top: 5.9em !important;
    padding: 0px;
    height: 2em;
}
.video-list-thumbs .card-img-overlay .card-text
{
    background-color: rgba(0, 0, 0, 0.7);
    color:white;
    border-radius: 5px;
    padding: 0 5px 0 5px;
}
.chrome{
	display:-webkit-inline-box;
}
.chrome  img{
	height:2em;
    margin-right:1em;
}
.ep_thumb
{
    padding-left: 5px !important;
    padding-right: 5px !important;
}
.kopimi img
{
    width: 10em;
}
.user img.card-img-top 
{
    width: 10em;
}
.subscribe 
{
    float:right;
    color:#014c8c;
}
.watch 
{
    float:right;
    color:#014c8c;
    padding-right: 1em;
}
.list-group-item.filters
{
    display: flex;
}
.bannedVideo
{
    background: rgba(39,62,84,0.82) url('/public/img/banned.png') no-repeat;
    position: absolute;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px !important;
    top: 0em !important;
    width:100%;
    height:100%;
}

@media (min-width:320px) and (max-width: 480px) { 
	.video-list-thumbs .glyphicon-play-circle{
    font-size: 35px;
    right: 36%;
    top: 27%;
	}
	.video-list-thumbs h2{
		bottom: 0;
		font-size: 12px;
		height: 22px;
		margin: 8px 0 0;
	}
}
